.logo-main {
  position: absolute;
  display: block;

  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  margin-bottom: 2%;
  cursor: pointer;
}
