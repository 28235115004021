.steps-fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.steps-fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.steps-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
}

.steps-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-bottom: 10rem;
  transition: margin-bottom 0.5s ease;
}

@media only screen and (max-width: 1200px) {
  .steps-content {
    margin-top: 20%;
    flex-direction: column;
    gap: 20px;
  }
}
