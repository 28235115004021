.nav-button {
    width: 180px;
    padding: 15px 39px;

    border: 2px solid #FFAD00;
    border-radius: 70px;
    backdrop-filter: blur(2px); 
    background-color: #58585833;

    color: white;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-weight: 700;
    text-transform: uppercase;

    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease, color 0.3s ease, backdrop-filter 0.3s ease, border-color 0.3s ease;
}

.nav-button:hover,
.nav-button.clicked {
    background-color: #FFAD00;
    backdrop-filter: none;
    color: black;
}

.nav-button.unlocked:hover,
.nav-button.unlocked.clicked {
    background-color: #1E6F30;
    color: white;
}

@media only screen and (max-width: 600px) {
    .nav-button {
        width: 120px;
        padding: 16px 56px;
    }

    .nav-button:hover,
    .nav-button.clicked,
    .nav-button.unlocked:hover,
    .nav-button.unlocked.clicked {
        background-color: #58585833;
        color: white;
    }
}
