.connect-btn {
    padding: 13px 39px 13px;
    border: 2px solid #FFAD00;
    border-radius: 70px;
    
    backdrop-filter: blur(2px); 
    background-color: #58585833;

    color: white;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;

    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease, color 0.3s ease, backdrop-filter 0.3s ease;
}

.connect-btn:hover,
.connect-btn.clicked {
    background-color: #FFAD00;
    backdrop-filter: none;
    color: black;
}

.connect-btn.disabled {
    pointer-events: none;
}